

.wrapper{
    box-sizing: content-box;
    background-color: #ffffff;
    height: 70px;
    width: 400px;
    display: flex;
}
.words{
    overflow: hidden;
}
.word{
    display: block;
    height: 100%;
  width: 500px;
  z-index: 9;
    color: #0e6ffc;
    animation: spin_words 6s infinite;
}
@keyframes spin_words{
    10%{
        transform: translateY(-112%);
    }
    25%{
        transform: translateY(-100%);
    }
    35%{
        transform: translateY(-212%);
    }
    50%{
        transform: translateY(-200%);
    }
    60%{
        transform: translateY(-312%);
    }
    75%{
        transform: translateY(-300%);
    }
    85%{
        transform: translateY(-412%);
    }
    100%{
        transform: translateY(-400%);
    }
}